<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <!-- one -->
      <div class="flex justify-between items-center">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="far fa-credit-card text-22px text-oTeal"></i>
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.payment.headline.paymentGateway'
              )
            }}
          </span>
        </div>
        <div></div>
      </div>
      <div class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          type="text"
          :label="
            $t(
              'components.settingsManagement.payment.paymentGateway.paymentGateway.title'
            )
          "
          placeholder="Stripe"
          :showEditPencil="false"
          v-model="gatewayName"
        />
        <template v-if="isPaymentMethodPayone">
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.merchantId.title'
              )
            "
            :placeholder="payment.payone_mid"
            :showEditPencil="false"
            v-model="getMerchantId"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.portalId.title'
              )
            "
            :placeholder="payment.payone_portalid"
            :showEditPencil="false"
            v-model="getPortalId"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.accountId.title'
              )
            "
            :placeholder="payment.payone_aid"
            :showEditPencil="false"
            v-model="getAccountId"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.key.title'
              )
            "
            :placeholder="payment.payone_key"
            :showEditPencil="false"
            v-model="getPayoneKey"
          />
        </template>
        <template v-else-if="isPaymentMethodKKiaPay">
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.kkiapayLivePublicKey.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.kkiapay_live_public_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.kkiapayLivePrivateKey.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.kkiapay_live_private_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.kkiapayLiveSecret.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.kkiapay_live_secret)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.kkiapaySandboxSecret.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.kkiapay_sandbox_secret)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.kkiapaySandboxPublicKey.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.kkiapay_sandbox_public_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.kkiapaySandboxPrivateKey.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.kkiapay_sandbox_private_key)"
            :showEditPencil="false"
          />
        </template>
        <template v-else-if="isItMyFatoorahMethod">
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.testApiToken.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.sandbox_secret_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.apiToken.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.secret_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.javascriptSdkUrl.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.src_url)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.apiBaseUrl.title'
              )
            "
            :placeholder="getHiddenKey(gatewayInfo.api_url)"
            :showEditPencil="false"
          />
        </template>
        <template v-else-if="isItBeePayMethod">
          <SmartAppInput
            type="text"
            :label="`Client Id`"
            :placeholder="getHiddenKey(gatewayInfo.client_id)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Secret Key`"
            :placeholder="getHiddenKey(gatewayInfo.secret_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Username`"
            :placeholder="getHiddenKey(gatewayInfo.user_name)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Password`"
            :placeholder="getHiddenKey(gatewayInfo.password)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Base URL`"
            :placeholder="getHiddenKey(gatewayInfo.api_url)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Access Token URL`"
            :placeholder="getHiddenKey(gatewayInfo.beepay_acess_token_url)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Scope URL`"
            :placeholder="getHiddenKey(gatewayInfo.beepay_scope_url)"
            :showEditPencil="false"
          />
        </template>
        <template v-else-if="isItMxPlusMethod">
          <SmartAppInput
            type="text"
            :label="`Merchant ID`"
            :placeholder="getHiddenKey(gatewayInfo.client_id)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`CX Password`"
            :placeholder="getHiddenKey(gatewayInfo.secret_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`RSA Private Key`"
            :placeholder="getHiddenKey(gatewayInfo.extra_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Institution ID`"
            :placeholder="getHiddenKey(gatewayInfo.app_key)"
            :showEditPencil="false"
          />
          <SmartAppInput
            type="text"
            :label="`Website ID`"
            :placeholder="getHiddenKey(gatewayInfo.website_id)"
            :showEditPencil="false"
          />
        </template>
        <template v-else>
          <SmartAppInput
            type="text"
            :label="getClientIdLabel"
            :placeholder="payment.client_id"
            :showEditPencil="false"
            v-model="getSecretClientId"
          />
          <SmartAppInput
            type="text"
            :label="getSecretKeyLabel"
            :placeholder="payment.secret_key"
            :showEditPencil="false"
            v-model="getSecretSecretKey"
          />
          <!-- <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.appKey.title'
              )
            "
            :placeholder="payment.app_key"
            :showEditPencil="false"
            v-model="getSecretAppKey"
          /> -->
          <!-- <SmartAppInput
            type="text"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.extraKey.title'
              )
            "
            :placeholder="payment.extra_key"
            :showEditPencil="false"
            v-model="getSecretExtraKey"
          /> -->

          <SmartAppInput
            v-if="isItStripeMethod"
            type="richselect"
            :label="
              $t(
                'components.settingsManagement.payment.paymentGateway.supportedPaymentMethodTypes.title'
              )
            "
            :options="supportedPaymentMethodTypes"
            placeholder="Select method"
            :description="
              $t(
                'components.settingsManagement.payment.paymentGateway.supportedPaymentMethodTypes.description'
              )
            "
            :isMultipleChoice="true"
            v-model="paymentGatewayConfig.supported_payment_method_types"
            @save="
              onSave(
                'paymentGatewayConfig',
                'supported_payment_method_types',
                JSON.stringify($event)
              )
            "
          />
        </template>

        <div :key="`paymentGateway-${counter}`">
          <template v-for="(item, itemIndex) in paymentGatewaySettings">
            <SmartToggleInput
              v-if="
                item.variable === 'is_payment_method_tokenization_supported'
              "
              :key="itemIndex"
              :label="item.label"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="org[`${item.variable}`]"
              @save="onSave('org', `${item.variable}`, $event)"
            />
            <SmartToggleInput
              v-else
              :key="itemIndex"
              :label="item.label"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="paymentGateway[`${item.variable}`]"
              @save="onSave('paymentGateway', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- card-payment -->
      <div class="mt-10 w-full">
        <div class="flex justify-between items-center">
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-credit-card text-22px text-blue-500"></i>
            <span class="pl-3">{{
              $t('components.settingsManagement.payment.headline.card')
            }}</span>
          </div>
        </div>
        <div :key="`card-${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in cardSettings">
            <SmartToggleInput
              v-if="item.type === 'toggle'"
              :key="itemIndex"
              :label="item.label"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="card[`${item.variable}`]"
              @save="onSave('card', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`card_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="card[`${item.variable}`]"
              :haveUnitText="item.haveUnitText"
              :unitText="item.unitText"
              @save="onSave('card', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
      <!-- /trigger-payment -->

      <!-- trigger-payment -->
      <div class="mt-10 w-full">
        <div class="flex justify-between items-center">
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-wallet text-22px text-oPurple"></i>
            <span class="pl-3">{{
              $t(
                'components.settingsManagement.payment.headline.triggerPayment'
              )
            }}</span>
          </div>
        </div>
        <div :key="`triggerPayment-${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in triggerPaymentSettings">
            <SmartToggleInput
              v-if="item.type === 'toggle'"
              :key="itemIndex"
              :label="item.label"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="trigger[`${item.variable}`]"
              @save="onSave('payment', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`triggerPayment_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="trigger[`${item.variable}`]"
              :haveUnitText="item.haveUnitText"
              :unitText="item.unitText"
              :unitWidthVariant="item.unitWidthVariant"
              @save="onSave('payment', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
      <!-- /trigger-payment -->

      <!-- two -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="css-icon-topup" />
          <span class="pl-3">
            {{ $t('components.settingsManagement.payment.headline.topup') }}
          </span>
        </div>
      </div>
      <div :key="`topupPayment-${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in topupPaymentSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`topupPayment_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="topup[`${item.variable}`]"
            @save="onSave('payment', `${item.variable}`, $event)"
          />
        </template>
      </div>

      <!-- invoicing -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="css-icon-invoicing" />
          <span class="pl-3">
            {{ $t('components.settingsManagement.payment.headline.invoicing') }}
          </span>
        </div>
      </div>
      <div :key="`invoicingPayments-${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartAppInput
          :key="`invoicingPayments_${counter + 0}`"
          :rowId="`invoicingPayments_${0}`"
          :type="invoicingPaymentSettings[0].type"
          :label="invoicingPaymentSettings[0].label"
          :options="invoicingPaymentSettings[0].options"
          :placeholder="invoicingPaymentSettings[0].placeholder"
          :description="invoicingPaymentSettings[0].description"
          :isEdit="invoicingPaymentSettings[0].isEdit"
          v-model="org[`${invoicingPaymentSettings[0].variable}`]"
          @save="
            onSave('org', `${invoicingPaymentSettings[0].variable}`, $event)
          "
        />

        <SmartToggleInput
          :key="`invoicingPayments_${counter + 1}`"
          :rowId="`invoicingPayments_${1}`"
          :type="invoicingPaymentSettings[1].type"
          :label="invoicingPaymentSettings[1].label"
          :description="invoicingPaymentSettings[1].description"
          :onText="invoicingPaymentSettings[1].onText"
          :offText="invoicingPaymentSettings[1].offText"
          :onValue="invoicingPaymentSettings[1].onValue"
          :offValue="invoicingPaymentSettings[1].offValue"
          v-model="org[`${invoicingPaymentSettings[1].variable}`]"
          @save="
            onSave('org', `${invoicingPaymentSettings[1].variable}`, $event)
          "
        />

        <SmartAppInput
          v-if="org.should_apply_trip_cost_if_minimum_distance_not_exceeded"
          :key="`invoicingPayments_${counter + 2}`"
          :rowId="`invoicingPayments_${2}`"
          :type="invoicingPaymentSettings[2].type"
          :label="invoicingPaymentSettings[2].label"
          :options="invoicingPaymentSettings[2].options"
          :placeholder="invoicingPaymentSettings[2].placeholder"
          :description="invoicingPaymentSettings[2].description"
          :isEdit="invoicingPaymentSettings[2].isEdit"
          v-model="org[`${invoicingPaymentSettings[2].variable}`]"
          @save="
            onSave('org', `${invoicingPaymentSettings[2].variable}`, $event)
          "
        />
      </div>
      <!-- subscription (pass) -->
      <div class="flex justify-between items-center mt-10">
        <div class="flex items-center font-bold text-22px text-oCharcoal">
          <div class="css-icon-subscription" />
          <span class="pl-3">
            {{
              $t('components.settingsManagement.payment.headline.subscription')
            }}
          </span>
        </div>
      </div>
      <div :key="`subscription-${counter}`" class=" mt-3 pt-5 border-t-2">
        <SmartToggleInput
          :key="`subscription_${counter + 0}`"
          :rowId="`subscription_${0}`"
          :type="subscriptionSettings[0].type"
          :label="subscriptionSettings[0].label"
          :description="subscriptionSettings[0].description"
          :onText="subscriptionSettings[0].onText"
          :offText="subscriptionSettings[0].offText"
          :onValue="subscriptionSettings[0].onValue"
          :offValue="subscriptionSettings[0].offValue"
          v-model="org[`${subscriptionSettings[0].variable}`]"
          @save="onSave('org', `${subscriptionSettings[0].variable}`, $event)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { PaymentSettingsConfig } from '@/config/SettingsConfig'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { useEndpoints } from '@/composables'
export default {
  name: 'PaymentSettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
  },
  data() {
    return {
      settings: {},
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      paymentGateways: [],
      supportedPaymentMethodTypes: [
        { text: 'PayPal', value: 'PayPal' },
        { text: 'Card', value: 'Card' },
      ],
      payment: {
        payment_gateway: 'STRIPE',
        client_id: 'SECRET_SECRET_ID',
        secret_key: 'SECRET_SECRET_KEY',
        app_key: 'SECRET_SECRET_APP_KEY',
        extra_key: 'SECRET_SECRET_EXTRA_KEY',
        payone_mid: 'PAYONE_MERCHANT_ID',
        payone_portalid: 'PAYONE_PORTAL_ID',
        payone_aid: 'PAYONE_ACCOUNT_ID',
        payone_key: 'PAYONE_KEY',
      },

      paymentGateway: {
        is_on_ride_payment_enabled: null,
        is_wallet_payment_enabled: null,
        is_cash_payment_enabled: null,
      },
      paymentGatewayConfig: {
        id: '',
        supported_payment_method_types: ['Card'],
      },
      card: {
        card_delete_allowed: true,
        max_credit_card_count: null,
      },
      topup: {
        user_deposit: 0,
        user_min_balance: 0,
        user_balance_threshold: 0,
        user_init_balance: 0,
        user_topup_min_balance: 0,
        user_topup_max_balance: 0,
      },

      trigger: {
        trigger_payment_for_negetive_balance: false,
        send_mail_on_neg_balance_payment_attempt: false,
        negetive_balance_payment_attempt_interval: 0,
        negetive_balance_payment_max_attempts: 0,
        block_rider_after_max_neg_balance_attempt: false,
      },
      inPlaceEditingState: {
        topup_user_deposit: false,
        topup_user_min_balance: false,
        topup_user_balance_threshold: false,
        topup_user_init_balance: false,
        topup_user_topup_min_balance: false,
        topup_user_topup_max_balance: false,

        trigger: false,
      },

      org: {
        should_apply_trip_cost_if_minimum_distance_not_exceeded: false,
        minimum_distance_to_apply_trip_cost: 0,
        should_display_ride_pricing_with_tax_included: false,
        is_payment_method_tokenization_supported: false,
        is_auto_renewal_enabled_for_pass_subscriptions: false,
      },
      paymentGatewaySettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.paymentGateway.enableWalletPaymentMethod.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.paymentGateway.enableWalletPaymentMethod.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_wallet_payment_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.paymentGateway.enableCashPaymentMethod.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.paymentGateway.enableCashPaymentMethod.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_cash_payment_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.paymentGateway.enableDebitCreditCardPaymentForTrip.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.paymentGateway.enableDebitCreditCardPaymentForTrip.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_on_ride_payment_enabled',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.paymentGateway.enablePaymentTokenization.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.paymentGateway.enablePaymentTokenization.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_payment_method_tokenization_supported',
          isEdit: false,
        },
      ],
      cardSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.card.allowCardDeletion.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.card.allowCardDeletion.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'card_delete_allowed',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.card.maximumCreditCardCount.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.card.maximumCreditCardCount.description'
          ),
          placeholder: 'e.g 10',
          variable: 'max_credit_card_count',
          haveUnitText: true,
          unitText: 'Card',
          isEdit: false,
        },
      ],
      triggerPaymentSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.triggerPayment.triggerNegativeBalance.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.triggerPayment.triggerNegativeBalance.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'trigger_payment_for_negetive_balance',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.triggerPayment.sendEmailNotifications.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.triggerPayment.sendEmailNotifications.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'send_mail_on_neg_balance_payment_attempt',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.payment.triggerPayment.triggerInterval.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.triggerPayment.triggerInterval.description'
          ),
          options: [
            { text: '6', value: 6 },
            { text: '12', value: 12 },
            { text: '24', value: 24 },
            { text: '36', value: 36 },
            { text: '48', value: 48 },
          ],
          variable: 'negetive_balance_payment_attempt_interval',
          haveUnitText: true,
          unitText: 'Hours',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.payment.triggerPayment.maximumTriggerAttempts.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.triggerPayment.maximumTriggerAttempts.description'
          ),
          options: [
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
            { text: '9', value: 9 },
          ],
          variable: 'negetive_balance_payment_max_attempts',
          haveUnitText: true,
          unitText: 'Attempt',
          unitWidthVariant: 'large',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.triggerPayment.blockRiderAfterMaxAttempts.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.triggerPayment.blockRiderAfterMaxAttempts.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'block_rider_after_max_neg_balance_attempt',
          isEdit: false,
        },
      ],
      topupPaymentSettings: [
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.topupPayment.depositAmount.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.topupPayment.depositAmount.description'
          ),
          placeholder: 'e.g 0',
          variable: 'user_deposit',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.topupPayment.minimumTripAmount.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.topupPayment.minimumTripAmount.description'
          ),
          placeholder: 'e.g 10',
          variable: 'user_min_balance',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.topupPayment.minimumTopUpAmount.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.topupPayment.minimumTopUpAmount.description'
          ),
          placeholder: 'e.g 10',
          variable: 'user_topup_min_balance',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.topupPayment.maximumTopUpAmount.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.topupPayment.maximumTopUpAmount.description'
          ),
          placeholder: 'e.g 1000',
          variable: 'user_topup_max_balance',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.topupPayment.lowBalanceThreshold.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.topupPayment.lowBalanceThreshold.description'
          ),
          placeholder: 'e.g 1000',
          variable: 'user_balance_threshold',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.topupPayment.signupBonus.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.topupPayment.signupBonus.description'
          ),
          placeholder: 'e.g 10',
          variable: 'user_init_balance',
          isEdit: false,
        },
      ],
      invoicingPaymentSettings: [
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.payment.invoicing.customizeRiderPricingDisplay.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.invoicing.customizeRiderPricingDisplay.description'
          ),
          options: [
            {
              text: this.$t(
                'components.settingsManagement.payment.invoicing.customizeRiderPricingDisplay.option.pricingWithTax'
              ),
              value: true,
            },
            {
              text: this.$t(
                'components.settingsManagement.payment.invoicing.customizeRiderPricingDisplay.option.pricingWithoutTax'
              ),
              value: false,
            },
          ],
          variable: 'should_display_ride_pricing_with_tax_included',
          isEdit: false,
        },

        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.invoicing.enableInvoiceForDistanceCalculation.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.invoicing.enableInvoiceForDistanceCalculation.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_apply_trip_cost_if_minimum_distance_not_exceeded',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.payment.invoicing.specifyingDistanceBetweenPickupAndDropoffLocations.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.invoicing.specifyingDistanceBetweenPickupAndDropoffLocations.description'
          ),
          placeholder: 'e.g 0',
          variable: 'minimum_distance_to_apply_trip_cost',
          isEdit: false,
        },
      ],
      subscriptionSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.payment.subscription.enableAutoRenewalFeatureForSubscription.title'
          ),
          description: this.$t(
            'components.settingsManagement.payment.subscription.enableAutoRenewalFeatureForSubscription.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_auto_renewal_enabled_for_pass_subscriptions',
          isEdit: false,
        },
      ],
    }
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'paymentGateway') {
          this.triggerPaymentSettings = this.triggerPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.topupPaymentSettings = this.topupPaymentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.cardSettings = this.cardSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.invoicingPaymentSettings = this.invoicingPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'triggerPayment') {
          this.paymentGatewaySettings = this.paymentGatewaySettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.topupPaymentSettings = this.topupPaymentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.cardSettings = this.cardSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.invoicingPaymentSettings = this.invoicingPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'topupPayment') {
          this.paymentGatewaySettings = this.paymentGatewaySettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.triggerPaymentSettings = this.triggerPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.cardSettings = this.cardSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.invoicingPaymentSettings = this.invoicingPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'card') {
          this.paymentGatewaySettings = this.paymentGatewaySettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.topupPaymentSettings = this.topupPaymentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.triggerPaymentSettings = this.triggerPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.invoicingPaymentSettings = this.invoicingPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'invoicingPayments') {
          this.paymentGatewaySettings = this.paymentGatewaySettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.topupPaymentSettings = this.topupPaymentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.triggerPaymentSettings = this.triggerPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.cardSettings = this.cardSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'paymentGateway') {
        this.paymentGatewaySettings = this.paymentGatewaySettings.map(
          (item, itemIndex) => {
            if (id === `paymentGateway_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'paymentGateway'
      }
      if (idPrefix === 'card') {
        this.cardSettings = this.cardSettings.map((item, itemIndex) => {
          if (id === `card_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'card'
      }
      if (idPrefix === 'triggerPayment') {
        this.triggerPaymentSettings = this.triggerPaymentSettings.map(
          (item, itemIndex) => {
            if (id === `triggerPayment_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'triggerPayment'
      }
      if (idPrefix === 'topupPayment') {
        this.topupPaymentSettings = this.topupPaymentSettings.map(
          (item, itemIndex) => {
            if (id === `topupPayment_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'topupPayment'
      }
      if (idPrefix === 'invoicingPayments') {
        this.invoicingPaymentSettings = this.invoicingPaymentSettings.map(
          (item, itemIndex) => {
            if (id === `invoicingPayments_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'invoicingPayments'
      }
    })
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    paymentGatewayConfigId() {
      return this.paymentGatewayConfig.id
    },
    gatewayInfo() {
      return (
        this.$store.getters['auth/organizationInfo']
          .default_payment_gateway_config || null
      )
    },
    gatewayName() {
      const selectedPaymentGateway = this.paymentGateways.find(
        (item) => item.id === this.gatewayInfo.payment_gateway
      )
      console.log('selectedPaymentGateway', selectedPaymentGateway)
      return selectedPaymentGateway.name
    },
    isPaymentMethodPayone() {
      return this.gatewayName === 'Payone'
    },

    isPaymentMethodKKiaPay() {
      return this.gatewayName === 'KKiaPay'
    },
    isItStripeMethod() {
      return this.gatewayName === 'Stripe'
    },
    isItSTSPayoneMethod() {
      return this.gatewayName === 'STS PayOne'
    },
    isItMyFatoorahMethod() {
      return this.gatewayName === 'MyFatoorah'
    },
    isItBeePayMethod() {
      return this.gatewayName === 'BeePay'
    },
    isItMxPlusMethod() {
      return this.gatewayName === 'MX Plus'
    },
    getClientIdLabel() {
      if (this.isItStripeMethod) {
        return this.$t(
          'components.settingsManagement.payment.paymentGateway.privateKey.title'
        )
      }
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.settingsManagement.payment.paymentGateway.merchantId.title'
        )
      }

      return this.$t(
        'components.settingsManagement.payment.paymentGateway.clientId.title'
      )
    },
    getSecretKeyLabel() {
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.settingsManagement.payment.paymentGateway.authToken.title'
        )
      }

      return this.$t(
        'components.settingsManagement.payment.paymentGateway.secretKey.title'
      )
    },
    getSecretClientId() {
      return new xMan(this.gatewayInfo.client_id).asteriskify()
    },

    getSecretSecretKey() {
      console.log(this.gatewayInfo.secret_key)
      let str = this.gatewayInfo.secret_key.slice(
        this.gatewayInfo.secret_key.length - 30
      )
      let secret = new xMan(str).asteriskify()
      return secret
    },
    getSecretAppKey() {
      return new xMan(this.gatewayInfo.app_key).asteriskify()
    },
    getSecretExtraKey() {
      return new xMan(this.gatewayInfo.extra_key).asteriskify()
    },

    getMerchantId() {
      return new xMan(this.gatewayInfo.payone_mid).asteriskify()
    },
    getPortalId() {
      return new xMan(this.gatewayInfo.payone_portalid).asteriskify()
    },
    getAccountId() {
      return new xMan(this.gatewayInfo.payone_aid).asteriskify()
    },
    getPayoneKey() {
      return new xMan(this.gatewayInfo.payone_key).asteriskify()
    },

    /* getPaymentGatewayOptions() {
      // @here or @created, you might need to send req to fetch data
      return [
        { value: "STRIPE", text: "Stripe" },
        { value: "PAYPAL", text: "Paypal" },
        { value: "PEACHPAYMENT", text: "Peach Payment" }
      ];
    }, */
  },
  async created() {
    const orgReq = this.$http.get(useEndpoints.organization.details(this.orgId))

    const paymentReq = this.$http.get(
      PaymentSettingsConfig.api.topup.index(this.orgId)
    )
    const paymentGatewaysReq = this.$http.get(
      useEndpoints.dropdown.paymentGateways()
    )
    const requests = [orgReq, paymentReq, paymentGatewaysReq]

    try {
      const responses = await Promise.allSettled(requests)
      responses.map((response) => {
        if (response.status === 'fulfilled') {
          if (
            response.value.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            const orgRes = response.value
            this.paymentGateway.is_on_ride_payment_enabled =
              orgRes.data.is_on_ride_payment_enabled
            this.paymentGateway.is_wallet_payment_enabled =
              orgRes.data.is_wallet_payment_enabled
            this.paymentGateway.is_cash_payment_enabled =
              orgRes.data.is_cash_payment_enabled

            this.card.card_delete_allowed = orgRes.data.card_delete_allowed
            this.card.max_credit_card_count = orgRes.data.max_credit_card_count

            this.org.should_apply_trip_cost_if_minimum_distance_not_exceeded =
              orgRes.data.should_apply_trip_cost_if_minimum_distance_not_exceeded
            this.org.minimum_distance_to_apply_trip_cost =
              orgRes.data.minimum_distance_to_apply_trip_cost

            this.org.should_display_ride_pricing_with_tax_included =
              orgRes.data.should_display_ride_pricing_with_tax_included

            this.org.is_payment_method_tokenization_supported =
              orgRes.data.is_payment_method_tokenization_supported
            this.org.is_auto_renewal_enabled_for_pass_subscriptions =
              orgRes.data.is_auto_renewal_enabled_for_pass_subscriptions
            this.paymentGatewayConfig.id =
              orgRes.data?.default_payment_gateway_config?.id || null
            const supportedPaymentMethods =
              orgRes.data?.default_payment_gateway_config
                ?.supported_payment_method_types || null

            this.paymentGatewayConfig.supported_payment_method_types =
              (supportedPaymentMethods !== null) &
              (supportedPaymentMethods.length == 0)
                ? ['Card']
                : supportedPaymentMethods
          }
          if (
            response.value.config.url ===
            PaymentSettingsConfig.api.topup.index(this.orgId)
          ) {
            const paymentRes = response.value
            this.topup = paymentRes.data
            const {
              trigger_payment_for_negetive_balance,
              send_mail_on_neg_balance_payment_attempt,
              negetive_balance_payment_attempt_interval,
              negetive_balance_payment_max_attempts,
              block_rider_after_max_neg_balance_attempt,
            } = paymentRes.data
            this.trigger = {
              trigger_payment_for_negetive_balance,
              send_mail_on_neg_balance_payment_attempt,
              negetive_balance_payment_attempt_interval,
              negetive_balance_payment_max_attempts,
              block_rider_after_max_neg_balance_attempt,
            }
          }
          if (
            response.value.config.url ===
            useEndpoints.dropdown.paymentGateways()
          ) {
            this.paymentGateways = response.value.data.data
          }
        }
        if (response.status === 'rejected') {
          let message = ''
          if (
            response.reason.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            message = `Org Details: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            PaymentSettingsConfig.api.topup.index(this.orgId)
          ) {
            message = `Payment Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.dropdown.paymentGateways()
          ) {
            message = `Payment Gateways: ${response.reason.response.data.detail}`
          }

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Error [${response.reason.response.status}]`,
              text: message,
            },
            5000
          )
        }
      })
    } catch (err) {
      console.log('Error loading data', { err })
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    getHiddenKey(visibleKey) {
      return new xMan(visibleKey).asteriskify()
    },
    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'card' || apiGroup === 'paymentGateway') {
        url = useEndpoints.organization.update(this.orgId)
      }
      if (apiGroup === 'payment') {
        url = PaymentSettingsConfig.api.topup.update(this.orgId)
      }
      if (apiGroup === 'org') {
        url = useEndpoints.organization.details(this.orgId)
      }

      if (apiGroup === 'paymentGatewayConfig') {
        url = useEndpoints.paymentGateway.update(this.paymentGatewayConfigId)
      }

      let toastTitle, toastText

      if (key === 'is_on_ride_payment_enabled') {
        toastTitle = `Success`
        toastText = `Ride Payment status is updated`
      }
      if (key === 'is_wallet_payment_enabled') {
        toastTitle = `Success`
        toastText = `Wallet Payment status is updated`
      }
      if (key === 'is_cash_payment_enabled') {
        toastTitle = `Success`
        toastText = `Cash Payment status is updated`
      }
      if (key === 'max_credit_card_count') {
        toastTitle = `Max Credit Card Count`
        toastText = `Max Credit Card Count is updated`
      }
      if (key === 'card_delete_allowed') {
        toastTitle = `Card delete allowed `
        toastText = `Card delete allowed is updated`
      }

      if (key === 'trigger_payment_for_negetive_balance') {
        toastTitle = `Trigger Negative Balance`
        toastText = `Trigger Negative Balance settings is updated`
      }
      if (key === 'send_mail_on_neg_balance_payment_attempt') {
        toastTitle = `Send Email Notification`
        toastText = `Send Email Notification settings is updated`
      }
      if (key === 'negetive_balance_payment_attempt_interval') {
        toastTitle = `Trigger Interval`
        toastText = `Trigger Interval time settings is updated`
      }
      if (key === 'negetive_balance_payment_max_attempts') {
        toastTitle = `Max Trigger Attempts`
        toastText = `Max Trigger Attempts count is updated`
      }
      if (key === 'block_rider_after_max_neg_balance_attempt') {
        toastTitle = `Block Rider After Max Attempts`
        toastText = `Block Rider After Max Attempts settings is updated`
      }
      if (key === 'user_deposit') {
        toastTitle = `Deposit`
        toastText = `Deposit amount is updated`
      }
      if (key === 'user_min_balance') {
        toastTitle = `Minimum Trip Amount`
        toastText = `Minimum Trip Amount is updated`
      }
      if (key === 'user_topup_min_balance') {
        toastTitle = `Minimum Topup Amount`
        toastText = `Minimum Topup Amount is updated`
      }
      if (key === 'user_topup_max_balance') {
        toastTitle = `Maximum Topup Amount`
        toastText = `Maximum Topup Amount is updated`
      }
      if (key === 'user_balance_threshold') {
        toastTitle = `Low Balance Threshold`
        toastText = `Low Balance Threshold is updated`
      }
      if (key === 'user_init_balance') {
        toastTitle = `Signup Bonus`
        toastText = `Signup Bonus is updated`
      }
      if (key === 'should_apply_trip_cost_if_minimum_distance_not_exceeded') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'minimum_distance_to_apply_trip_cost') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'should_display_ride_pricing_with_tax_included') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'supported_payment_method_types') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'is_payment_method_tokenization_supported') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      if (key === 'is_auto_renewal_enabled_for_pass_subscriptions') {
        toastTitle = `Success`
        toastText = `Successfully changed`
      }
      let data = new FormData()
      data.append(key, val)

      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.paymentGatewaySettings = this.paymentGatewaySettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.cardSettings = this.cardSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.topupPaymentSettings = this.topupPaymentSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.triggerPaymentSettings = this.triggerPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.invoicingPaymentSettings = this.invoicingPaymentSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.css-icon-topup {
  width: 30px;
  height: 22px;
  border-radius: 0.2rem;
  background-color: #ffc747;
}

.css-icon-invoicing {
  width: 30px;
  height: 22px;
  border-radius: 0.2rem;
  background-color: #ff7b47;
}
.css-icon-subscription {
  width: 30px;
  height: 22px;
  border-radius: 0.2rem;
  background-color: #477bff;
}
.css-icon-others {
  width: 30px;
  height: 22px;
  border-radius: 0.2rem;
  background-color: #47ff56;
}
</style>
